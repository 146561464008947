<template>
  <v-app>
   

    <v-main>
      <MainComponent/>
                <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        <v-footer class="mt-8 primary lighten-1 white--text" 
      dark
      padless
    >
      <v-card
        flat
        tile style="margin:0 auto;"
        class="primary lighten-1 white--text text-center"
      >
        <v-card-text>
          <v-btn
            v-for="item in icons"
            :key="item.icon" :href="item.url" target="_blank"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Hobsonville Laundromat</strong><br>
          <span>powered by - <a style="color:#fff" href="https://www.itechief.com">iTechief Solutions</a></span>
        </v-card-text>
      </v-card>
    </v-footer>
    </v-main>
  </v-app>
</template>

<style>
@font-face {
  font-family: "BerlinSansFB";
  src: url('./assets/fonts/BerlinSansFB.ttf');
}

@font-face {
  font-family: "BaskervilleOldFace";
  src: url('./assets/fonts/BaskervilleOldFace.ttf');
}

.v-application{
  font-family: "BaskervilleOldFace", sans-serif;
}

button,h1,h2,h3,h4,h5,h6,a {
  font-family: "BerlinSansFB", sans-serif;
}

a{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
}

  @media screen and (max-width: 640px) {
    #logo{
    max-width:150px !important;
    }
    #split{
          flex-direction: column;
    }
  }

</style>

<script>
import MainComponent from './components/MainComponent';

export default {
  name: 'App',

  components: {
    MainComponent,
  },

  data: () => ({
              icons: [ 
                {
                  icon: 'mdi-facebook',
                  url: 'https://www.facebook.com/hobsonvillelaundromat2021/'
                },
                {
                  icon: 'mdi-instagram',
                  url: 'https://instagram.com/hobsonvillelaundromat'
                }
    ],
    fab: false
  }),

    methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
};
</script>

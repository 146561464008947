<template>
<v-container>
<v-app-bar elevation="0" color="white" >
        <v-img id="logo" class="mr-2" :src="require('../assets/logo.jpg')"  max-height="80" max-width="250"></v-img>
        <div class="d-none d-sm-none d-mg-flex d-lg-flex">
        <v-btn style="font-size: 1em;" href="#services" text>Services</v-btn>
        <v-btn style="font-size: 1em;" href="#whyus" text>Why us</v-btn>
        <v-btn style="font-size: 1em;" href="#pricing" text>Pricing</v-btn>
        <v-btn style="font-size: 1em;" href="#commercial" text>Commercial</v-btn>
        <v-btn style="font-size: 1em;" href="#store" text>Store</v-btn>
        </div>
  
        <v-spacer></v-spacer>
  
      <v-btn
        depressed
        color="accent" href="#contactus"
      >
        Contact us
      </v-btn>
        <v-app-bar-nav-icon class="d-lg-none d-md-none" @click="drawer = true"></v-app-bar-nav-icon>
      </v-app-bar>

       <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
      >
<v-list
          nav
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item @click="drawer = false" href="#services">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title >Services</v-list-item-title>
            </v-list-item>
  
            <v-list-item @click="drawer = false" href="#whyus">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Why us</v-list-item-title>
            </v-list-item>

                        <v-list-item @click="drawer = false" href="#pricing">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pricing</v-list-item-title>
            </v-list-item>

                        <v-list-item @click="drawer = false" href="#commercial">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Commercial</v-list-item-title>
            </v-list-item>

                        <v-list-item @click="drawer = false" href="#store">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Store</v-list-item-title>
            </v-list-item>

                                    <v-list-item @click="drawer = false" href="#contactus">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact us</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-divider class="mt-8"></v-divider>

  <v-row dense class="mt-8">
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12"  style="display:flex;align-items:center;"
          >
                <v-card class="mt-16" elevation="0" 
    >
        <h1 class="mb-4 main-title" style="font-size:6.6vmax;line-height: 72px;">Hobsonville <span style="color:#21b4e8;">Laundromat</span></h1>
  
      <v-card-text class="main-subtitle accent--text mb-8 pa-0" style="width:60%;font-size: 1.2em;">Wear clean and fresh attire. Ramp up your confidence.<v-img style="width:1.5em;" :src="require('../assets/smile.png')" alt="smiley"></v-img>
      </v-card-text>
      <div>
          <h2 class="main-title">GRAND OPENING on Saturday 15th May 2021 !!!!</h2>
      </div>
  <br>
      <v-card-actions class="pa-0">
        <v-btn href="#services"
          color="accent" elevation="0"
        >
          Services
        </v-btn>
  
        <v-btn href="#pricing"
          color="primary" elevation="0"
        >
          Pricing
        </v-btn>
      </v-card-actions>

  
      
    </v-card>
          </v-col>
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12" 
          >
           <v-card class="mt-16" elevation="0"
    >
      <v-img
        class="white--text align-center pa-16"
        height="500px"
        :src="require('../assets/banner.png')"
      ></v-img></v-card>
          </v-col>
          </v-row>


           <div id="stats">
              <v-img style="background-size: contain;"
        :src="require('../assets/banner4.png')"
      ></v-img>
    
      </div>

      <v-divider class="mt-8"></v-divider>


    <div  id="services">
      <h2 style="border-bottom: 5px solid #21b4e8;font-style: italic;
    display: table;
    margin: 0 auto;" class="mt-16">SERVICES</h2>
      <v-row dense class="mt-8">
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12" 
          >
            <v-card>
              <v-img
                :src="require('./../assets/banner3.png')"
                class="white--text align-end"
                height="200px"
              >
              
              </v-img>
  <v-card-title style="font-size:1.2em; font-family:BerlinSansFB; color: #333;">DOMESTIC LAUNDRY SERVICES</v-card-title>
                    <v-card-text>
  
        <div style="min-height:50px;font-size: 1.2em;">
          <ul>- Wash and Dry</ul>
          <ul>- Folding</ul>
          <ul>- Ironing</ul>
          <ul>- Drop and pick up</ul>
          <ul>- Home Delivery</ul>
        </div>
      </v-card-text>
            </v-card>
          </v-col>
                    <v-col
             :lg="6" :md="6" :sm="6" :xs="12" 
          >
            <v-card>
              <v-img
                :src="require('../assets/banner6.png')"
                class="white--text align-end"
                height="200px"
              >
              
              </v-img>
  <v-card-title style="font-size:1.2em; font-family:BerlinSansFB; color: #333;">COMMERCIAL LAUNDRY & DRY CLEANING SERVICES</v-card-title>
                    <v-card-text>
  
        <div style="min-height:50px;font-size: 1.2em;">- Similar services available for Commercial purposes.</div>
      </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </div>

     <div class="mt-10" id="whyus">
      <h2 style="border-bottom: 5px solid #21b4e8;font-style: italic;
    display: table;
    margin: 0 auto;">WHY US</h2>
       <v-row dense class="mt-8">
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12" 
          >
                <v-img
        class="accent--text align-center"
        height="500px"
        :src="require('../assets/banner1.png')"
        position="center top"
      ></v-img>
          </v-col>
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12" style="display:flex;align-items:center;justify-content:center;"
          >
          <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            color="primary"
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Our machines</strong>
              </v-col>
              <v-col>
                <strong>Easy to Use</strong>
                <div class="caption">
                  Walk in, press the button, wash and head back home for your hot pie.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
  
          <v-timeline-item
            color="accent"
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Our Staff</strong>
              </v-col>
              <v-col>
                <strong>Simply Awesome</strong>
                <div class="caption mb-2">
                  Get in touch with are in store person for any questions, we are more than happy to help.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
  
          <v-timeline-item
            color="primary"
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Our Store</strong>
              </v-col>
              <v-col>
                <strong>Best in Hobsonville</strong>
                <div class="caption mb-2">
                  Best store to get your wash done in the Neighborhood. Easily accessible.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
  
          <v-timeline-item
            color="accent"
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Our Customer Support</strong>
              </v-col>
              <v-col>
                <strong>!00% Available</strong>
                <div class="caption">
                  We will be completely Available to resolve any problems you face in the store.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
          </v-col>
          </v-row>
      </div>

      
           <div id="pricing" class="mt-10">
           <h2 style="border-bottom: 5px solid #21b4e8;font-style: italic; display: table; margin: 0 auto;">PRICING</h2>
           <br>
              <v-img style="background-size: contain;"
        :src="require('../assets/banner5.png')"
      ></v-img>
    
      </div>

      <div id="commercial" class="mt-10">
        <h2 style="border-bottom: 5px solid #21b4e8;font-style: italic;
    display: table;
    margin: 0 auto;">COMMERCIAL SERVICES</h2>
              <v-img
        class=" mt-8"
        height="500px"
        :src="require('../assets/banner2.png')"
      ></v-img>
      <p class="mt-8" style="font-size: 1.1em;">Hobsonville is a growing suburb and considering the future growth in this area, our laundromat has been set up to serve all nearby communities. Highly dense suburbs like Hobsonville point where residents may not have perfect clothing line facilities or limited spaces for washers and dryers, a laundromat plays an important role. 
<br><br>
Our services include domestic laundry services such as wash and dry, 
folding, ironing, drop and pick up, home delivery and dry cleaning. 
We also offer similar services in commercial space.
<br><br>
<i><b>We are committed to provide Quality services!</b></i>
</p>

      </div>

      <div id="store">
              <h2 style="border-bottom: 5px solid #21b4e8;font-style: italic;
    display: table;
    margin: 0 auto;">OUR STORE</h2>
      <v-row dense class="mt-8" id="split">
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12"  style="display:flex;align-items:flex-start;"
          >
                <v-card  elevation="0" style="display:flex;align-items:flex-start;flex-direction:column;"
    >
    <div style="display:flex;align-items:flex-start;">
    <div class="mr-8" id="hours">
    <h4> Store hours </h4>
    <ul class="pa-0 mt-0" style="list-style:none;">
    <li class="pa-0 mt-0">Mon - Fri: 8AM- 9PM</li>
    <li class="pa-0 mt-0">Sat - Sun: 8AM - 9PM</li>
    </ul>  
</div>
<div id="attended">
    <h4>Attended services</h4> 
     <ul class="pa-0 mt-0" style="list-style:none;">
    <li class="pa-0 mt-0">Mon - Fri: 8AM- 9PM</li></ul>
</div>
</div>
<div id="contactus" class="mt-8">
    <h4>Contact us </h4>
    <div    style=" display: flex;
    align-items: center;">
    <p class="ma-0">Ring us at: </p>
      <v-btn
        text href="tel:092189176"
        color="accent"
      >            <v-icon size="24px">
            mdi-phone
            </v-icon>
        092189176
      </v-btn>
            <v-btn
        text href="tel:0225960700"
        color="accent"
      >            <v-icon size="24px">
            mdi-phone
            </v-icon>
        022 596 0700
      </v-btn>
      </div>
      <div class="mt-1">
      <p>Emait us at: <a href="mailto: hobsonvillelaundromat2021@gmail.com">hobsonvillelaundromat2021@gmail.com</a></p>
      </div>
      <div class="mt-1">
      <p>Like us on <a href="https://www.facebook.com/hobsonvillelaundromat2021/" target="_blank">Facebook</a></p>
      </div>
      <div class="mt-1">
      <h4>Dry Cleaning </h4>
      <p><a href="https://www.maxwellsdrycleaningakl.co.nz" target="_blank">www.maxwellsdrycleaningakl.co.nz</a></p>
      </div>
  </div>
<div id="address" class="mt-8">
    <h4>Address </h4>
    <p>120A Hobsonville Road, Hobsonville, Auckland 0618</p>
      <v-btn
        depressed
        color="accent" target="_blank" href="https://www.google.co.nz/maps/place?q=Hobsonville+Laundromat"
      >
        Get Directions
      </v-btn>
  </div>
      
    </v-card>
          </v-col>
          <v-col
             :lg="6" :md="6" :sm="6" :xs="12" 
          >
      <iframe
  style="border:0;  position: relative;
    height: 100%;
    width: 100%; "
  loading="lazy"
  allowfullscreen
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDlWCW7Wp1FM-1tJlplrHM_sIjyr-ECV_o&q=Hobsonville+Laundromat">
</iframe>
          </v-col>
          </v-row>
      </div>

      

    </v-container>
</template>

<script>
  export default {
    name: 'MainComponent',

    data: () => ({
        drawer: false,
    }),
  }
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#21b4e8',
          secondary: '#f4f4f4',
          accent: '#333',
        },
      },
    },
  })

export default vuetify